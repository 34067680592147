import logo from "../images/LOGO.svg"
import comingSoon from "../images/coming_soon.svg"
/*import checkOut from "../images/check_out.svg"
import facebook from "../images/facebook.svg"
import instagram from "../images/instagram.svg"*/
import React from 'react'
import "../components/layout.css"
import GlitchClip from 'react-glitch-effect/core/Clip';
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'


const index = () => {
    
    return (
        
            <div>

      <Helmet title="Schrödingers - Coming Soon">
        <link rel="icon" href={favicon} />
      </Helmet>
                
                <div className="container">
                    <div className="inner-container">
                        <div className="textTopContainer"><img src={comingSoon} alt="Coming Soon"/></div>
                        
                        <GlitchClip>
                        <img className="image" src={logo} alt="Schrödingers Logo"/>
                        </GlitchClip>

                        {/*<div className="textBottomContainer">
                            <img src={checkOut} alt="Check Out"/>
                            <img src={facebook} alt="Facebook"/>
                            <img src={instagram} alt="Instagram"/>
                        </div>*/}
                        
                        
                    </div>
                </div>
                
            </div>
        
    )
}

export default index

